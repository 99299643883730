"use client";
import { usePathname } from "next/navigation";

export const RobotsTags: React.FC = () => {
  const pathname = usePathname();

  const baseUrl =
    typeof window !== "undefined"
      ? `${window.location.protocol}//${window.location.host}`
      : "";

  const indexed =
    baseUrl === "https://outshift.cisco.com" &&
    !pathname.startsWith("/admin") &&
    !pathname.startsWith("/preview") &&
    !pathname.startsWith("/outshift-newsletter-thank-you");

  return (
    <>
      {indexed ? (
        <meta name="robots" content="index, follow" />
      ) : (
        baseUrl !== "" && <meta name="robots" content="noindex,nofollow" />
      )}
    </>
  );
};
